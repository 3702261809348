export default function useTime() {
  const getTime = function(type: 'from' | 'to', date: Date, precission?: 'y' | 'm' | 'd' | 'h' | 'm') {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
  
    const seconds = Math.floor(
      type === 'from'
        ? (new Date().getTime() - date.getTime())
        : (date.getTime() - new Date().getTime())
      / 1000
    )

    let intervalType
  
    let interval = Math.floor(seconds / 31536000)
    if (interval >= 1 || precission === 'y') {
      intervalType = 'y'
    } else {
      interval = Math.floor(seconds / 2592000)
      if (interval >= 1 || precission === 'm') {
        intervalType = 'm'
      } else {
        interval = Math.floor(seconds / 86400)
        if (interval >= 1 || precission === 'd') {
          intervalType = 'd'
        } else {
          interval = Math.floor(seconds / 3600)
          if (interval >= 1 || precission === 'h') {
            intervalType = "h"
          } else {
            interval = Math.floor(seconds / 60)
            if (interval >= 1 || precission === 'm') {
              intervalType = "m"
            } else {
              interval = seconds;
              intervalType = "now"
            }
          }
        }
      }
    }
  
    // if (interval > 1 || interval === 0) intervalType += 's'
  
    return interval + ' ' + intervalType
  };

  return { getTime }
}