import { computed, reactive, SetupContext } from "@vue/composition-api"
import { AxiosResponse } from "axios"

export default function useContact({ root }: Partial<SetupContext>) {
  const hasUnread = reactive({
    current: computed(() => root?.$store.getters['contact/hasUnreadCurrent']),
    other: computed(() => root?.$store.getters['contact/hasUnreadOther']),
    total: computed(() => root?.$store.getters['contact/hasUnreadTotal'])
  })

  const getUnread = reactive({
    current: computed(() => root?.$store.getters['contact/getUnreadCurrent']),
    other: computed(() => root?.$store.getters['contact/getUnreadOther']),
    total: computed(() => root?.$store.getters['contact/getUnreadTotal'])
  })

  const fetchUnreadFrom = (id: string) =>
    root?.$store.dispatch('contact/fetchUnreadFrom', id) as Promise<AxiosResponse>

  return { hasUnread, getUnread, fetchUnreadFrom }
}