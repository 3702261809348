


































































































































































































































































import useAlert from "@/use/alert";
import useContact from "@/use/contact";
import useTime from "@/use/time";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions, DataTableHeader } from "vuetify";
import moment from "moment";
import { Dictionary } from "vue-router/types/router";
import { nextTick } from "process";
import useUser from "@/use/user";
import { watchDebounced } from "@vueuse/shared";

interface RowClickEventData {
  expand: (value: boolean) => void;
  headers: DataTableHeader[];
  isExpanded: boolean;
  isMobile: boolean;
  isSelected: boolean;
  item: any;
  select: (value: boolean) => void;
}

export default defineComponent({
  components: {
    MVehicleType: () => import("@/components/molecules/m-vehicle-type.vue"),
    OAddComment: () => import("@/components/organisms/o-add-comment.vue"),
    OEditComment: () => import("@/components/organisms/o-edit-comment.vue"),
    ODeleteComment: () => import("@/components/organisms/o-delete-comment.vue"),
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
  },

  props: {
    short: {
      type: Boolean,
      required: false,
    },
    highlight: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { fetchUnreadFrom } = useContact({ root });
    const { getTime } = useTime();

    const {
      alertData,
      getAlertType,
      getAlertTypeIcon,

      getAlertTimeLeft,
      getAlertMileageLeft,

      getAlertStatus,
      getAlertStatusColor,

      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertEndMileageColor,

      getAlertVehicle,
      getAlertCompany,
    } = useAlert();

    const state = reactive({
      headers: computed(() => {
        const headers = [];

        headers.push({
          text: "",
          value: "details.type",
          sortable: false,
          width: 1,
        });
        headers.push({
          text: "Nr. rej.",
          value: "details.registrationNumber",
          width: 1,
        });
        headers.push({ text: "Marka/Model", value: "details.vehicleBrand" });
        headers.push({ text: "Przebieg", value: "mileages.mileage" });
        if (hasAccessTo.value("customer"))
          headers.push({ text: "Kierowca", value: "driver", sortable: false });
        if (hasAccessTo.value("customer"))
          headers.push({
            text: "Struktura",
            value: "structure.name",
          });
        if (hasAccessTo.value("customer"))
          headers.push({
            text: "Komentarz",
            value: "comment",
            sortable: false,
            width: 1,
          });
        headers.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
          width: 1,
        });

        return headers;
      }),
      items: [] as any[],
      options: {
        page: 1 as number,
        itemsPerPage: 20 as number,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        multiSort: false as boolean,
        mustSort: false as boolean,
      },
      total: 0,
      loading: true,
      queryLoading: false,
      loaded: false,
      contactTimer: null as any,
    });

    watch(
      () => state.options,
      (o) => {
        if (root.$route.name === "panel.vehicle.list") {
          const query: Dictionary<
            string | (string | null)[] | null | undefined
          > = {};

          if (o.page) query["vehicles.page"] = o.page.toString();
          if (o.itemsPerPage)
            query["vehicles.items_per_page"] = o.itemsPerPage.toString();
          if (o.sortBy && o.sortBy.length)
            query["vehicles.sort_by"] = o.sortBy.join(",");
          if (o.sortDesc && o.sortDesc.length)
            query["vehicles.sort_desc"] = o.sortDesc
              .map((i) => (i ? "true" : "false"))
              .join(",");

          root.$router.replace({ query }).catch(() => []);
        }
      }
    );

    onMounted(() => {
      if (root.$route.name === "panel.vehicle.list") {
        const query = root.$route.query;

        state.queryLoading = true;

        nextTick(() => {
          if (
            query["vehicles.page"] &&
            typeof query["vehicles.page"] === "string"
          )
            state.options.page = parseInt(query["vehicles.page"]);
          if (
            query["vehicles.items_per_page"] &&
            typeof query["vehicles.items_per_page"] === "string"
          )
            state.options.itemsPerPage = parseInt(
              query["vehicles.items_per_page"]
            );
          if (
            query["vehicles.sort_by"] &&
            typeof query["vehicles.sort_by"] === "string"
          )
            state.options.sortBy = query["vehicles.sort_by"]
              .split(",")
              .filter((i) => typeof i === "string") as string[];
          if (
            query["vehicles.sort_desc"] &&
            typeof query["vehicles.sort_desc"] === "string"
          )
            state.options.sortDesc = query["vehicles.sort_desc"]
              .split(",")
              .map((i) => i === "true");

          setTimeout(() => (state.queryLoading = false), 2000);
        });
      }
    });

    const model = reactive({
      company: null as any,
      search: "",
    });

    const unreadFrom = ref<{ id: string; count: number }[]>([]);

    const visibleIds = computed<string[]>(() =>
      state.items
        .filter((item) => (item.driver ? true : false))
        .map((item) => item.driver.id)
    );

    const onRowClick = (event: MouseEvent, data: RowClickEventData) => {
      root.$router.push({
        name: "panel.vehicle.view",
        params: { id: data.item.id },
      });
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      const filter = {
        search: model.search ?? undefined,
        company: model.company ? model.company.id : undefined,
      };

      axiosInstance
        .get("vehicle", {
          params: { sortBy, sortDesc, page, itemsPerPage, filter },
        })
        .then(({ data: { vehicles, total } }) => {
          state.items = vehicles;
          state.total = total;
        })
        .catch(() => [])
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const fetchUnread = async () => {
      for (const id of visibleIds.value) {
        await fetchUnreadFrom(id)
          .then(({ data: { count } }) => {
            const item = unreadFrom.value.find((i) => i.id === id);

            if (item) item.count = count;
            else unreadFrom.value.push({ id, count });
          })
          .catch(console.log);
      }
    };

    const getUnreadFrom = (id: string) => {
      if (
        unreadFrom &&
        unreadFrom.value &&
        unreadFrom.value.find((i) => i.id === id)
      ) {
        return unreadFrom.value.find((i) => i.id === id)?.count as number;
      } else {
        return 0;
      }
    };

    const hasUnreadFrom = (id: string) => {
      return getUnreadFrom(id) > 0;
    };

    watchDebounced(() => [state.options, model], fetchData, {
      deep: true,
      debounce: 500,
      maxWait: 5000,
      immediate: true,
    });

    watch(model, () => (state.options.page = 1), { deep: true });

    watch(() => visibleIds.value, fetchUnread);
    watch(() => state.options, fetchUnread, { deep: true });
    onMounted(() => (state.contactTimer = setInterval(fetchUnread, 5000)));
    onBeforeUnmount(() =>
      state.contactTimer ? clearInterval(state.contactTimer) : void 0
    );

    const getAlertButtonIcon = (vehicle: any) => {
      if (vehicle && vehicle.alerts && vehicle.alerts.length > 0) {
        if (vehicle.alerts.length === 1) {
          const alert = vehicle.alerts[0];
          if (alert) return getAlertTypeIcon(alert.type) || "mdi-alert";
        } else {
          return "mdi-alert";
        }
      }

      return null;
    };

    const getAlertButtonColor = (vehicle: any) => {
      if (vehicle && vehicle.alerts && vehicle.alerts.length > 0) {
        if (vehicle.alerts.length === 1) {
          const alert = vehicle.alerts[0];
          if (alert)
            return getAlertEndDate(alert)
              ? getAlertEndDateColor(alert)
              : getAlertEndMileageColor(alert, vehicle);
        } else {
          const colors: string[] = [];

          for (const alert of vehicle.alerts) {
            colors.push(getAlertEndDateColor(alert));
            colors.push(getAlertEndMileageColor(alert, vehicle));
          }

          if (colors.find((color) => ["error", "red"].includes(color)))
            return "warning";
          else return "success";
        }
      }

      return null;
    };

    const getMileageColor = (
      mileage: {
        date: {
          start: Date;
          end: Date;
        };
        mileage: number;
      },
      leasing: {
        startDate: Date;
        endDate: Date;
        startMileage: number;
        mileage: number;
        installment: number;
      }
    ) => {
      if (!leasing || !leasing.mileage) {
        return null;
      } else if (mileage) {
        const diff = Math.abs(
          moment(mileage.date.start).diff(moment(mileage.date.end), "days")
        );
        const daysOfYear = moment({
          day: 31,
          month: 12 - 1,
          year: moment(mileage.date.start).year(),
        }).dayOfYear();
        const ratio = diff / daysOfYear;
        const maxPeriodMileage = leasing.mileage * ratio;

        if (mileage.mileage > maxPeriodMileage) return "error";
        else return null;
      }
    };

    const getBulkMileageColor = (
      leasingMileages: {
        month: {
          date: {
            start: Date;
            end: Date;
          };
          mileage: number;
        };
        quarter: {
          date: {
            start: Date;
            end: Date;
          };
          mileage: number;
        };
        year: {
          date: {
            start: Date;
            end: Date;
          };
          mileage: number;
        };
      },
      leasing: {
        startDate: Date;
        endDate: Date;
        startMileage: number;
        mileage: number;
        installment: number;
      }
    ) => {
      if (!leasing || !leasing.mileage) {
        return null;
      } else {
        const colors = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_, mileage] of Object.entries(leasingMileages)) {
          colors.push(getMileageColor(mileage, leasing));
        }

        if (colors.find((c) => c === "error")) return "error";
        else return null;
      }
    };

    const getDateOf = (
      period: "month" | "quarter" | "year",
      time: "start" | "end"
    ) => {
      let date = moment().subtract(1, period);

      if (time === "start") date = date.startOf(period);
      else date = date.endOf(period);

      return date;
    };

    const getDays = (
      period: "month" | "quarter" | "year",
      item: any = null
    ) => {
      if (item && item.leasingMileages && item.leasingMileages[period]) {
        const start = (getDateOf(period, "start").isAfter(
          item.leasing.startDate,
          "day"
        )
          ? item.leasingMileages[period].date.start
          : item.leasing.startDate) as Date;

        const end = (getDateOf(period, "end").isBefore(
          item.leasing.endDate,
          "day"
        )
          ? item.leasingMileages[period].date.end
          : item.leasing.endDate) as Date;

        return moment(end).diff(moment(start), "days") + 1;
      } else {
        const date = moment().subtract(1, period);
        return date.endOf(period).diff(date.startOf(period));
      }
    };

    const groupByCompany = computed(
      () =>
        (hasAccessTo.value("employee") || hasManyCompanies.value()) &&
        !(model.company || null)
    );

    const groupBy = computed(() =>
      groupByCompany.value ? "company.id" : null
    );

    return {
      moment,
      hasAccessTo,
      hasManyCompanies,

      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertEndMileageColor,
      getAlertVehicle,
      getAlertCompany,

      state,
      model,
      onRowClick,
      getUnreadFrom,
      hasUnreadFrom,
      fetchData,

      getAlertButtonIcon,
      getAlertButtonColor,

      getMileageColor,
      getBulkMileageColor,
      getDateOf,
      getDays,

      getTime,

      groupBy,
    };
  },
});
